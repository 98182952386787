<h3>Plans</h3>

<h5>Select Year</h5>
<div class="yearSelection">
    <ng-template [ngIf]="!selectedYear">
        <svg (click)="priorYear()" width="1.5em" height="1.5em" viewBox="0 0 16 16"
            class="bi bi-dash-circle clickPointer" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" d="M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
            <path fill-rule="evenodd" d="M4 8a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7A.5.5 0 0 1 4 8z" />
        </svg>
        <div class="yearItem">
            <h1>{{currentYear}}</h1>
        </div>

        <svg (click)="nextYear()" width="1.5em" height="1.5em" viewBox="0 0 16 16"
            class="bi bi-plus-circle clickPointer" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" d="M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
            <path fill-rule="evenodd"
                d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" />
        </svg>
    </ng-template>
    <ng-template [ngIf]="selectedYear">
        <div class="yearItem" (click)="clearSelectedYear()">{{selectedYear}}</div>
    </ng-template>

</div>

<h5>Manage Categories</h5>
<div class="categoryCreation">
    <form [formGroup]="newCategory" (ngSubmit)="addCategory(newCategory.value)">
        <label for="exampleInputEmail1" style="margin-right:10px;">Name</label>
        <input type="text" formControlName="name" aria-describedby="emailHelp" style="margin-right:10px;">
        <label for="exampleInputPassword1" style="margin-right:10px;">Amount</label>
        <input type="number" min="0" formControlName="amount" aria-describedby="emailHelp" style="margin-right:10px;">
        <button type="submit" [disabled]="!newCategory.valid" class="btn btn-primary">
            <svg width="1.3em" height="1.3em" viewBox="0 0 16 16" class="bi bi-plus-square-fill" fill="currentColor"
                xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd"
                    d="M2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2zm6.5 4.5a.5.5 0 0 0-1 0v3h-3a.5.5 0 0 0 0 1h3v3a.5.5 0 0 0 1 0v-3h3a.5.5 0 0 0 0-1h-3v-3z" />
            </svg>
        </button>
    </form>
</div>

<!--table class="table table-striped categoriesTable">
    <thead>
        <tr>
            <th>NAME</th>
            <th>JAN</th>
            <th>FEB</th>
            <th>MAR</th>
            <th>APR</th>
            <th>MAY</th>
            <th>JUN</th>
            <th>JUL</th>
            <th>AUG</th>
            <th>SEP</th>
            <th>OCT</th>
            <th>NOV</th>
            <th>DEC</th>
        </tr>
    </thead>
    <tbody>
        <tr *ngFor="let category of categories; let id = index">
            <td>
                <span (blur)="updateList(id, 'name', $event)" contenteditable="true">{{category.name}}</span>
            </td>
            <td>
                <span contenteditable="true" (blur)="updateList(id, 'jan', $event)">{{category.jan}}</span>
            </td>
            <td>
                <span contenteditable="true" (blur)="updateList(id, 'feb', $event)">{{category.feb}}</span>
            </td>
            <td>
                <span contenteditable="true" (blur)="updateList(id, 'mar', $event)">{{category.mar}}</span>
            </td>
            <td>
                <span contenteditable="true" (blur)="updateList(id, 'apr', $event)">{{category.apr}}</span>
            </td>
            <td>
                <span contenteditable="true" (blur)="updateList(id, 'may', $event)">{{category.may}}</span>
            </td>
            <td>
                <span contenteditable="true" (blur)="updateList(id, 'jun', $event)">{{category.jun}}</span>
            </td>
            <td>
                <span contenteditable="true" (blur)="updateList(id, 'jul', $event)">{{category.jul}}</span>
            </td>
            <td>
                <span contenteditable="true" (blur)="updateList(id, 'aug', $event)">{{category.aug}}</span>
            </td>
            <td>
                <span contenteditable="true" (blur)="updateList(id, 'sep', $event)">{{category.sep}}</span>
            </td>
            <td>
                <span contenteditable="true" (blur)="updateList(id, 'oct', $event)">{{category.oct}}</span>
            </td>
            <td>
                <span contenteditable="true" (blur)="updateList(id, 'nov', $event)">{{category.nov}}</span>
            </td>
            <td>
                <span contenteditable="true" (blur)="updateList(id, 'dec', $event)">{{category.dec}}</span>
            </td>
            <td>
                <span class="clickPointer">
                    <svg (click)="remove(id)" width="1.5em" height="1.5em" viewBox="0 0 16 16" class="bi bi-trash-fill"
                        fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd"
                            d="M2.5 1a1 1 0 0 0-1 1v1a1 1 0 0 0 1 1H3v9a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V4h.5a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H10a1 1 0 0 0-1-1H7a1 1 0 0 0-1 1H2.5zm3 4a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 .5-.5zM8 5a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7A.5.5 0 0 1 8 5zm3 .5a.5.5 0 0 0-1 0v7a.5.5 0 0 0 1 0v-7z" />
                    </svg>
                </span>
            </td>
        </tr>
        <tr *ngIf="totals" class="totalsRow">
            <td>
                <span contenteditable="false">{{totals.name}}</span>
            </td>
            <td>
                <span contenteditable="false">{{totals.jan}}</span>
            </td>
            <td>
                <span contenteditable="false">{{totals.feb}}</span>
            </td>
            <td>
                <span contenteditable="false">{{totals.mar}}</span>
            </td>
            <td>
                <span contenteditable="false">{{totals.apr}}</span>
            </td>
            <td>
                <span contenteditable="false">{{totals.may}}</span>
            </td>
            <td>
                <span contenteditable="false">{{totals.jun}}</span>
            </td>
            <td>
                <span contenteditable="false">{{totals.jul}}</span>
            </td>
            <td>
                <span contenteditable="false">{{totals.aug}}</span>
            </td>
            <td>
                <span contenteditable="false">{{totals.sep}}</span>
            </td>
            <td>
                <span contenteditable="false">{{totals.oct}}</span>
            </td>
            <td>
                <span contenteditable="false">{{totals.nov}}</span>
            </td>
            <td>
                <span contenteditable="false">{{totals.dec}}</span>
            </td>
            <td>
            </td>
        </tr>
    </tbody>
</table-->
<div class="categoriesContainer">
    <div *ngFor="let category of categories; let id = index" class="categoryItem">
        <div class="categoryName" contenteditable="true" (blur)="updateList(id, 'name', $event)">{{category.name}}</div>
        <div class="categoryStart">
            Start from: <span contenteditable="true" (blur)="updateList(id, 'startValue', $event)">{{category.startValue}}</span>€
        </div>
        <div class="months">
            <div class="mounthItem">JAN</div>
            <div class="mounthItem">FEB</div>
            <div class="mounthItem">MAR</div>
            <div class="mounthItem">APR</div>
            <div class="mounthItem">MAY</div>
            <div class="mounthItem">JUN</div>
            <div class="mounthItem">JUL</div>
            <div class="mounthItem">AUG</div>
            <div class="mounthItem">SEP</div>
            <div class="mounthItem">OCT</div>
            <div class="mounthItem">NOV</div>
            <div class="mounthItem">DEC</div>
        </div>
        <div class="months">
            <div class="mounthItem"><span contenteditable="true" (blur)="updateList(id, 'jan', $event)">{{category.jan}}</span>€</div>
            <div class="mounthItem"><span contenteditable="true" (blur)="updateList(id, 'feb', $event)">{{category.feb}}</span>€</div>
            <div class="mounthItem"><span contenteditable="true" (blur)="updateList(id, 'mar', $event)">{{category.mar}}</span>€</div>
            <div class="mounthItem"><span contenteditable="true" (blur)="updateList(id, 'apr', $event)">{{category.apr}}</span>€</div>
            <div class="mounthItem"><span contenteditable="true" (blur)="updateList(id, 'may', $event)">{{category.may}}</span>€</div>
            <div class="mounthItem"><span contenteditable="true" (blur)="updateList(id, 'jun', $event)">{{category.jun}}</span>€</div>
            <div class="mounthItem"><span contenteditable="true" (blur)="updateList(id, 'jul', $event)">{{category.jul}}</span>€</div>
            <div class="mounthItem"><span contenteditable="true" (blur)="updateList(id, 'aug', $event)">{{category.aug}}</span>€</div>
            <div class="mounthItem"><span contenteditable="true" (blur)="updateList(id, 'sep', $event)">{{category.sep}}</span>€</div>
            <div class="mounthItem"><span contenteditable="true" (blur)="updateList(id, 'oct', $event)">{{category.oct}}</span>€</div>
            <div class="mounthItem"><span contenteditable="true" (blur)="updateList(id, 'nov', $event)">{{category.nov}}</span>€</div>
            <div class="mounthItem"><span contenteditable="true" (blur)="updateList(id, 'dec', $event)">{{category.dec}}</span>€</div>
        </div>
    </div>
    <div class="categoryItem">
        <div class="categoryName">Totals</div>
        <div class="months">
            <div class="mounthItem">JAN</div>
            <div class="mounthItem">FEB</div>
            <div class="mounthItem">MAR</div>
            <div class="mounthItem">APR</div>
            <div class="mounthItem">MAY</div>
            <div class="mounthItem">JUN</div>
            <div class="mounthItem">JUL</div>
            <div class="mounthItem">AUG</div>
            <div class="mounthItem">SEP</div>
            <div class="mounthItem">OCT</div>
            <div class="mounthItem">NOV</div>
            <div class="mounthItem">DEC</div>
        </div>
        <div class="months">
            <div class="mounthItem"><span>{{totals.jan}}</span>€</div>
            <div class="mounthItem"><span>{{totals.feb}}</span>€</div>
            <div class="mounthItem"><span>{{totals.mar}}</span>€</div>
            <div class="mounthItem"><span>{{totals.apr}}</span>€</div>
            <div class="mounthItem"><span>{{totals.may}}</span>€</div>
            <div class="mounthItem"><span>{{totals.jun}}</span>€</div>
            <div class="mounthItem"><span>{{totals.jul}}</span>€</div>
            <div class="mounthItem"><span>{{totals.aug}}</span>€</div>
            <div class="mounthItem"><span>{{totals.sep}}</span>€</div>
            <div class="mounthItem"><span>{{totals.oct}}</span>€</div>
            <div class="mounthItem"><span>{{totals.nov}}</span>€</div>
            <div class="mounthItem"><span>{{totals.dec}}</span>€</div>
        </div>
    </div>
</div>
<div class="savePlan">
    <button (click)="savePlan()" type="button" class="btn btn-dark">Save Plan</button>
</div>