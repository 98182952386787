import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './login/login.component';
import { AngularFireAuthGuard } from '@angular/fire/auth-guard';
import { SetupComponent } from './page/setup/setup.component';
import { SetupPlanComponent } from './setup-plan/setup-plan.component';
import { ExpensesComponent } from './page/expenses/expenses.component';
import { MoneyBoxComponent } from './page/money-box/money-box.component';
import { PortfolioComponent } from './page/portfolio/portfolio.component';

const routes: Routes = [
  { path: 'login', component: LoginComponent },
  { path: '', pathMatch: 'full', redirectTo: '/transaction' },
  { path: 'transaction', component: ExpensesComponent },
  { path: 'moneyBox', component: MoneyBoxComponent },
  { path: 'portFolio', component: PortfolioComponent },
  {
    path: 'setup', component: SetupComponent, canActivate: [AngularFireAuthGuard], children: [
      { path: '', pathMatch: 'full', redirectTo: '/setup/(setup:plans)' },
      { path: 'plans', component: SetupPlanComponent, outlet: 'setup' }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
