<div class="componentContainer">
    <div class="componentMainBar">
        <div class="componentTitle">Portfolio</div>
        <svg (click)="toggleShowAddContainer()" ngbTooltip="Add Symbol" width="2em" height="2em" viewBox="0 0 16 16"
            class="bi bi-plus-circle addSymbol tableIcon clickPointer" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" d="M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
            <path fill-rule="evenodd"
                d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" />
        </svg>
        <svg ngbTooltip="Save" (click)="savePortfolio()" style="margin-left: 10px;" width="2em" height="2em" viewBox="0 0 16 16"
                class="bi bi-cloud-upload tableIcon clickPointer addSymbol" fill="currentColor"
                xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd"
                    d="M4.406 1.342A5.53 5.53 0 0 1 8 0c2.69 0 4.923 2 5.166 4.579C14.758 4.804 16 6.137 16 7.773 16 9.569 14.502 11 12.687 11H10a.5.5 0 0 1 0-1h2.688C13.979 10 15 8.988 15 7.773c0-1.216-1.02-2.228-2.313-2.228h-.5v-.5C12.188 2.825 10.328 1 8 1a4.53 4.53 0 0 0-2.941 1.1c-.757.652-1.153 1.438-1.153 2.055v.448l-.445.049C2.064 4.805 1 5.952 1 7.318 1 8.785 2.23 10 3.781 10H6a.5.5 0 0 1 0 1H3.781C1.708 11 0 9.366 0 7.318c0-1.763 1.266-3.223 2.942-3.593.143-.863.698-1.723 1.464-2.383z" />
                <path fill-rule="evenodd"
                    d="M7.646 4.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1-.708.708L8.5 5.707V14.5a.5.5 0 0 1-1 0V5.707L5.354 7.854a.5.5 0 1 1-.708-.708l3-3z" />
            </svg>
    </div>
    <div class="overViewBar">
        <div class="overViewBar_Item"> <span>Invested amount:</span> {{portfolioValue | number: '1.0-2'}} </div>
        <div class="overViewBar_Item"> <span>Portfolio value:</span> {{portfolioActualValue | number: '1.0-2'}} </div>
        <div [className]="(portfolioActualValue - portfolioValue) < 0 ? 'overViewBar_Item redColor' : 'totalItem greenColor'"> <span>Total gain:</span> {{(portfolioActualValue - portfolioValue)| number: '1.0-2'}} ({{((portfolioActualValue - portfolioValue) / portfolioValue * 100)| number: '1.0-2'}}%) </div>
        <div [className]="(portfolioActualValue - totalchartPreviousClose) < 0 ? 'overViewBar_Item redColor' : 'totalItem greenColor'"> <span>Daily gain:</span> {{(portfolioActualValue - totalchartPreviousClose) | number: '1.0-2'}} ({{((portfolioActualValue - totalchartPreviousClose) / totalchartPreviousClose * 100)| number: '1.0-2'}}%) </div>
    </div>

    <div *ngFor="let symbol of mySymbols; let id = index" class="borderBottom">
        <div class="symbolInfoItem" (click)="toggleOpenSection(id)">
            <div class="symbolInfoOpenIcon hiddenOnMobile">
                <svg *ngIf="!openSectionArray[id]" width="1em" height="1em" viewBox="0 0 16 16"
                    class="bi bi-caret-right" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd"
                        d="M6 12.796L11.481 8 6 3.204v9.592zm.659.753l5.48-4.796a1 1 0 0 0 0-1.506L6.66 2.451C6.011 1.885 5 2.345 5 3.204v9.592a1 1 0 0 0 1.659.753z" />
                </svg>
                <svg *ngIf="openSectionArray[id]" width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-caret-down"
                    fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd"
                        d="M3.204 5L8 10.481 12.796 5H3.204zm-.753.659l4.796 5.48a1 1 0 0 0 1.506 0l4.796-5.48c.566-.647.106-1.659-.753-1.659H3.204a1 1 0 0 0-.753 1.659z" />
                </svg>
            </div>
            <div class="symbolInfoItemLeft">
                <div class="symbolLongName">{{symbol.longname}}</div>
                <div class="symbolSymbol">{{symbol.symbol}} <span> [ {{symbol.quoteType}} ] </span> </div>
            </div>
            <ng-template [ngIf]="symbolsPrice[id]">
                <div class="priceRecap ">
                    <div class="priceRecapTitle">Market Price</div>
                    <div class="priceRecapAmount">{{symbolsPrice[id].meta.regularMarketPrice | number: '1.0-2'}} </div>
                </div>

                <div class="priceRecap hiddenOnMobile">
                    <div class="priceRecapTitle">Daily Gain</div>
                    <div [className]="symbolsPrice[id].meta.totalDailyGain < 0 ? 'priceRecapAmount redColor' : 'priceRecapAmount greenColor'">{{symbolsPrice[id].meta.totalDailyGain | number: '1.0-2'}} </div>
                    <div [className]="symbolsPrice[id].meta.totalDailyGainPerc < 0 ? 'priceRecapPerc redColor' : 'priceRecapPerc greenColor'">{{symbolsPrice[id].meta.totalDailyGainPerc | number: '1.0-2'}}%</div>
                </div>

                <div class="priceRecap">
                    <div class="priceRecapTitle">Total Gain</div>
                    <div [className]="symbolsPrice[id].meta.totalGain < 0 ? 'priceRecapAmount redColor' : 'priceRecapAmount greenColor'">{{symbolsPrice[id].meta.totalGain | number: '1.0-2'}} </div>
                    <div [className]="symbolsPrice[id].meta.totalGainPerc < 0 ? 'priceRecapPerc redColor' : 'priceRecapPerc greenColor'">{{symbolsPrice[id].meta.totalGainPerc | number: '1.0-2'}}%</div>
                </div>

            </ng-template>
            <div class="hiddenOnMobile">
                <svg ngbTooltip="Delete" (click)="removeSymbol(id)" width="1.5em" height="1.5em" viewBox="0 0 16 16"
                    class="bi bi-trash clickPointer" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z" />
                    <path fill-rule="evenodd"
                        d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4L4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z" />
                </svg>
            </div>
        </div>
        <div *ngIf="openSectionArray[id]" class="lotsDetail hiddenOnMobile">
            <div class="lotsContainer header">
                <div class="lotTradeDate">Trade Date</div>
                <div class="lotShares">Shares</div>
                <div class="lotCost">Cost</div>
                <div class="lotDayGain">Day Gain</div>
                <div class="lotTotalGain">Total Gain</div>
                <div class="lotCommission">Commission</div>
                <div class="lotButton">
                    <svg (click) = "addLot(id)" ngbTooltip="Add Lot" width="1.2em" height="1.2em"
                        viewBox="0 0 16 16" class="bi bi-plus-circle addSymbol clickPointer" fill="currentColor"
                        xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd"
                            d="M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                        <path fill-rule="evenodd"
                            d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" />
                    </svg>
                </div>
            </div>
            <form *ngFor="let lot of symbol.lots; let lotId = index" class="lotsContainer">
                <input class="lotTradeDate" type="text" placeholder="Trade Date" [(ngModel)]="lot.tradeDate" [ngModelOptions]="{standalone: true}"/>
                <input class="lotShares" type="number" min="0" step="1" placeholder="Share number" [(ngModel)]="lot.shares" [ngModelOptions]="{standalone: true}"/>
                <input class="lotCost" type="number" min="0" step="0.1" placeholder="Cost" [(ngModel)]="lot.cost" [ngModelOptions]="{standalone: true}"/>
                <div [className]="lot.dayGain < 0 ? 'lotDayGain redColor' : 'lotDayGain greenColor'">{{lot.dayGain | number: '1.0-2'}} ({{lot.dayGainPerc | number: '1.0-2'}}%)</div>
                <div [className]="lot.totalGain < 0 ? 'lotDayGain redColor' : 'lotDayGain greenColor'">{{lot.totalGain | number: '1.0-2'}} ({{lot.totalGainPerc | number: '1.0-2'}}%)</div>
                <input class="lotCommission" type="number" min="0" step="0.1" placeholder="Commission" [(ngModel)]="lot.commission" [ngModelOptions]="{standalone: true}"/>
                <div class="lotButton">
                    <svg (click)="deleteLot(id, lotId)" ngbTooltip="Delete lot" width="1.2em" height="1.2em" viewBox="0 0 16 16"
                    class="bi bi-trash clickPointer" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z" />
                    <path fill-rule="evenodd"
                        d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4L4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z" />
                </svg>
                </div>
            </form>
        </div>
    </div>
</div>

<div *ngIf="showAddContainer" class="addSymbolBackground">
    <div class="closeAddSymbol">
        <div class="editMobileClose" (click)="toggleShowAddContainer()">
            <svg width="1.5em" height="1.5em" viewBox="0 0 16 16" class="bi bi-x-square" fill="currentColor"
                xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd"
                    d="M14 1H2a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z" />
                <path fill-rule="evenodd"
                    d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
            </svg>
        </div>
    </div>
    <div class="addSymbolContainer">
        <div class="title">Search Symbol</div>
        <input (keyup)="searchSymbol($event)" placeholder="Symbol Name" type="text" />

        <div *ngFor="let symbol of symbols; let id = index" class="symbolInfoItem" (click)="addToSymbols(id)">
            <div class="symbolInfoItemLeft">
                <div class="symbolLongName">{{symbol.longname}}</div>
                <div class="symbolSymbol">{{symbol.symbol}}</div>
            </div>
            <div class="symbolInfoItemRight">
                {{symbol.quoteType}}
            </div>

        </div>
    </div>
</div>