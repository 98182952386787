<div class="componentContainer">
    <div class="componentMainBar">
        <div class="componentTitleWithSelection">
            <svg ngbTooltip="Previous Year" (click)="previousYear()" width="2em" height="2em" viewBox="0 0 16 16"
                class="bi bi-caret-left-fill clickPointer" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M3.86 8.753l5.482 4.796c.646.566 1.658.106 1.658-.753V3.204a1 1 0 0 0-1.659-.753l-5.48 4.796a1 1 0 0 0 0 1.506z" />
            </svg>
            <div class="componentTitleWithSelection_TitleSection">
                <div class="componentTitleWithSelection_Title">Moneybox of</div>
                <h1 class="componentTitleWithSelection_Selection">{{currentYear}}</h1>
            </div>
            <svg ngbTooltip="Next Year" (click)="nextYear()" width="2em" height="2em" viewBox="0 0 16 16"
                class="bi bi-caret-right-fill clickPointer" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M12.14 8.753l-5.482 4.796c-.646.566-1.658.106-1.658-.753V3.204a1 1 0 0 1 1.659-.753l5.48 4.796a1 1 0 0 1 0 1.506z" />
            </svg>
        </div>
    </div>

    <div *ngIf="isLoading" class="spinnerSection">
        <div class="spinner-border" role="status">
            <span class="sr-only">Loading...</span>
        </div>
    </div>
    <div class="overViewBar">
        <div class="overViewBar_Item"> <span>Money Box Total:</span> {{totalAmount}}€ </div>
    </div>

    <div class="overviewContainer">
        <div *ngFor="let moneyBox of moneyBoxList " class="overviewItem">
            <div class="overviewCategory">
                <h6>{{moneyBox.name}}</h6>
            </div>
            <div [className]="moneyBox.residue < 0 ? 'overviewAmount redColor' : 'overviewAmount greenColor'">
                <h5>{{moneyBox.residue}}€</h5>
            </div>
            <div class="overviewResidue">
                <h5>{{moneyBox.amount}}€</h5>
            </div>
        </div>
    </div>
</div>