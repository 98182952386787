<div class="loginContainer d-flex justify-content-center align-items-center">
    <div class="loginBox">
        <div class="loginTitle">
            <svg width="10em" height="10em" viewBox="0 0 16 16" class="bi bi-bar-chart-line-fill" fill="currentColor"
                xmlns="http://www.w3.org/2000/svg">
                <path d="M14 3H1a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1h-1z" />
                <path fill-rule="evenodd"
                    d="M15 5H1v8h14V5zM1 4a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h14a1 1 0 0 0 1-1V5a1 1 0 0 0-1-1H1z" />
                <path
                    d="M13 5a2 2 0 0 0 2 2V5h-2zM3 5a2 2 0 0 1-2 2V5h2zm10 8a2 2 0 0 1 2-2v2h-2zM3 13a2 2 0 0 0-2-2v2h2zm7-4a2 2 0 1 1-4 0 2 2 0 0 1 4 0z" />
            </svg>
            <h1>My Finance</h1>
        </div>
        <div class="loginForm">
            <form [formGroup]="loginForm" (ngSubmit)="onSubmit(loginForm.value)">
                <div class="form-group">
                    <label for="exampleInputEmail1">Email address</label>
                    <input type="email" class="form-control" formControlName="email" aria-describedby="emailHelp">
                    <small id="emailHelp" class="form-text text-muted">We'll never share your email with anyone
                        else.</small>
                </div>
                <div class="form-group">
                    <label for="exampleInputPassword1">Password</label>
                    <input type="password" class="form-control" formControlName="password">
                </div>
                <div class="loginSubmission d-flex justify-content-center align-items-center">
                    <button type="submit" [disabled]="!loginForm.valid" class="btn btn-primary">Login</button>
                </div>
            </form>
        </div>
    </div>
</div>