<div class="componentContainer">
    <div class="componentMainBar">
        <div class="componentTitleWithSelection">
            <svg ngbTooltip="Previous Month" (click)="previousMonth()" width="2em" height="2em" viewBox="0 0 16 16"
                class="bi bi-caret-left-fill clickPointer" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M3.86 8.753l5.482 4.796c.646.566 1.658.106 1.658-.753V3.204a1 1 0 0 0-1.659-.753l-5.48 4.796a1 1 0 0 0 0 1.506z" />
            </svg>
            <div class="componentTitleWithSelection_TitleSection">
                <div class="componentTitleWithSelection_Title">Transactions of {{currentYear}}</div>
                <h1 class="componentTitleWithSelection_Selection">{{currentMonth}}</h1>
            </div>
            <svg ngbTooltip="Next Month" (click)="nextMonth()" width="2em" height="2em" viewBox="0 0 16 16"
                class="bi bi-caret-right-fill clickPointer" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M12.14 8.753l-5.482 4.796c-.646.566-1.658.106-1.658-.753V3.204a1 1 0 0 1 1.659-.753l5.48 4.796a1 1 0 0 1 0 1.506z" />
            </svg>
        </div>

        <div class="componentMainBar_iconSection">
            <svg ngbTooltip="New transaction" (click)="addExpense()" width="2em" height="2em" viewBox="0 0 16 16"
                class="bi bi-plus-circle iconSectionIcon clickPointer" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" d="M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                <path fill-rule="evenodd"
                    d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" />
            </svg>
            <svg ngbTooltip="Import from XLS" (click)="toggleExcelImport()" width="2em" height="2em" viewBox="0 0 16 16"
                class="bi bi-file-earmark-spreadsheet iconSectionIcon clickPointer importExcel" fill="currentColor"
                xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" d="M5 10H3V9h10v1h-3v2h3v1h-3v2H9v-2H6v2H5v-2H3v-1h2v-2zm1 0v2h3v-2H6z" />
                <path
                    d="M4 0h5.5v1H4a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V4.5h1V14a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2z" />
                <path d="M9.5 3V0L14 4.5h-3A1.5 1.5 0 0 1 9.5 3z" />
            </svg>
            <svg ngbTooltip="Save" (click)="uploadExpences()" width="2em" height="2em" viewBox="0 0 16 16"
                class="bi bi-cloud-upload iconSectionIcon clickPointer" fill="currentColor"
                xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd"
                    d="M4.406 1.342A5.53 5.53 0 0 1 8 0c2.69 0 4.923 2 5.166 4.579C14.758 4.804 16 6.137 16 7.773 16 9.569 14.502 11 12.687 11H10a.5.5 0 0 1 0-1h2.688C13.979 10 15 8.988 15 7.773c0-1.216-1.02-2.228-2.313-2.228h-.5v-.5C12.188 2.825 10.328 1 8 1a4.53 4.53 0 0 0-2.941 1.1c-.757.652-1.153 1.438-1.153 2.055v.448l-.445.049C2.064 4.805 1 5.952 1 7.318 1 8.785 2.23 10 3.781 10H6a.5.5 0 0 1 0 1H3.781C1.708 11 0 9.366 0 7.318c0-1.763 1.266-3.223 2.942-3.593.143-.863.698-1.723 1.464-2.383z" />
                <path fill-rule="evenodd"
                    d="M7.646 4.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1-.708.708L8.5 5.707V14.5a.5.5 0 0 1-1 0V5.707L5.354 7.854a.5.5 0 1 1-.708-.708l3-3z" />
            </svg>

        </div>
    </div>
    <textarea *ngIf="showExcelImport" (paste)="showMapExcelColumns($event, content)" placeholder="Paste Excel Here"
        class="form-control" rows="3"></textarea>
    <div *ngIf="isLoading" class="spinnerSection">
        <div class="spinner-border" role="status">
            <span class="sr-only">Loading...</span>
        </div>
    </div>
    <div class="overViewBar">
        <div class="overViewBar_Item"> <span>Income:</span> {{totalIncome}}€ </div>
        <div class="overViewBar_Item"> <span>Outcome:</span> {{totalOutcome}}€ </div>
        <div class="overViewBar_Item"> <span>Balance:</span> {{totalBalance}}€ </div>
        <div class="overViewBar_Item"> <span>Extra Income:</span> {{totalExtraIncome}}€ </div>
        <div class="overViewBar_Item"> <span>Extra Outcome:</span> {{totalExtraOutcome}}€ </div>
    </div>
    <ng-template [ngIf]="!isMobileView">
        <div *ngFor="let expense of expenses; let id = index" class="expenseItem">
            <div class="expenseItemFirstItem">
                <div class="bold" contenteditable="true" (blur)="updateList(id, 'date', $event)">{{expense.date}}</div>
            </div>
            <div class="expenseItemSecondItem">
                <div class="bold" contenteditable="true" (blur)="updateList(id, 'name', $event)">{{expense.name}}</div>
                <div class="lighter hidden-md-down" contenteditable="true"
                    (blur)="updateList(id, 'description', $event)">
                    {{expense.description}}</div>
            </div>
            <div class="expenseItemThirdItem">
                <select class="darkSelect" (change)="updateList(id, 'category', $event)">
                    <option *ngFor="let category of currentMonthCategories" [selected]="category == expense.category"
                        [value]="expense.category">{{category}}</option>
                </select>
            </div>
            <div class="expenseItemFourthItem">
                <div [className]="expense.amount < 0 ? 'expenseAmount bold red' : 'expenseAmount bold green'">
                    <span contenteditable="true" (blur)="updateList(id, 'amount', $event)">{{expense.amount}}</span>
                    <span>€</span>
                </div>
            </div>
            <div class="removeItem">
                <svg ngbTooltip="Delete" (click)="remove(id)" width="1.5em" height="1.5em" viewBox="0 0 16 16"
                    class="bi bi-trash clickPointer" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z" />
                    <path fill-rule="evenodd"
                        d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4L4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z" />
                </svg>
            </div>
        </div>
    </ng-template>

    <ng-template [ngIf]="isMobileView">
        <div *ngFor="let expense of expenses; let id = index" class="expenseItemMobile"
            (click)="openEditTransactionMobile(id)">
            <div class="expenseItemFirstItemMobile">
                <div class="bold" (blur)="updateList(id, 'date', $event)">{{expense.date}}</div>
                <div class="bold" (blur)="updateList(id, 'name', $event)">{{expense.name}}</div>
                <div class="lighter hidden-md-down" (blur)="updateList(id, 'description', $event)">{{expense.category}}
                </div>
            </div>
            <div class="expenseItemSecondItemMobile">
                <div
                    [className]="expense.amount < 0 ? 'expenseAmount bold redMobile' : 'expenseAmount bold greenMobile'">
                    <span (blur)="updateList(id, 'amount', $event)">{{expense.amount}}</span>
                    <span>€</span>
                </div>
            </div>
        </div>
    </ng-template>


    <hr class="pageSeparator">
    <div class="secondTitle">Transactions Overview</div>
    
    <div class="overviewContainer">
        <div *ngFor="let total of categoryTotals " class="overviewItem">
            <div class="overviewCategory">
                <h6>{{total.name}}</h6>
            </div>
            <div [className]="total.residue < 0 ? 'overviewAmount redColor' : 'overviewAmount greenColor'">
                <h5>{{total.total}}€</h5>
            </div>
            <div class="overviewResidue">
                <h5>{{total.residue}}€</h5>
            </div>
        </div>
    </div>
</div>


<ng-template #content let-modal>
    <div class="importXLSModal">
        <div class="modal-header">
            <h4 class="modal-title" id="modal-basic-title">Map Excel Columns</h4>
            <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body">
            <div>
                <div class="form-group">
                    <label for="exampleFormControlSelect1">Date</label>
                    <select class="form-control" (change)="addToExcelMapping('date', $event)">
                        <option *ngFor="let column of xlsColumns">{{column}}</option>
                    </select>
                </div>
            </div>
            <div>
                <div class="form-group">
                    <label for="exampleFormControlSelect1">Name</label>
                    <select class="form-control" (change)="addToExcelMapping('name', $event)">
                        <option *ngFor="let column of xlsColumns">{{column}}</option>
                    </select>
                </div>
            </div>
            <div>
                <div class="form-group">
                    <label for="exampleFormControlSelect1">Description</label>
                    <select class="form-control" (change)="addToExcelMapping('description', $event)">
                        <option *ngFor="let column of xlsColumns">{{column}}</option>
                    </select>
                </div>
            </div>
            <div>
                <div class="form-group">
                    <label for="exampleFormControlSelect1">Amount</label>
                    <select class="form-control" (change)="addToExcelMapping('amount', $event)">
                        <option *ngFor="let column of xlsColumns">{{column}}</option>
                    </select>
                </div>
            </div>

        </div>
        <div class="modal-footer">
            <button type="button" class="btn btn-outline-dark" (click)="importXlsData()">Import</button>
        </div>
    </div>
</ng-template>

<div *ngIf="editMobileIsOpen" class="editTransactionMobile">
    <div class="editMobileHeader">
        <div class="secondTitle">Edit Transaction</div>
        <div class="editMobileClose" (click)="closeEditTransactionMobile()">
            <svg width="1.5em" height="1.5em" viewBox="0 0 16 16" class="bi bi-x-square" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" d="M14 1H2a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z"/>
                <path fill-rule="evenodd" d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
              </svg>
        </div>
    </div>
    <form class="editMobileForm">
        <input type="text" placeholder="Name" [(ngModel)]="expenses[editMobileSelectedId].name" [ngModelOptions]="{standalone: true}"/>
        <textarea placeholder="Description" rows="3" [(ngModel)]="expenses[editMobileSelectedId].description" [ngModelOptions]="{standalone: true}"></textarea>
        <input type="text" placeholder="Date" [(ngModel)]="expenses[editMobileSelectedId].date" [ngModelOptions]="{standalone: true}"/>
        <input type="number" placeholder="Amount" [(ngModel)]="expenses[editMobileSelectedId].amount" [ngModelOptions]="{standalone: true}"/>
        <select class="darkSelect" [(ngModel)]="expenses[editMobileSelectedId].category" [ngModelOptions]="{standalone: true}">
            <option *ngFor="let category of currentMonthCategories">{{category}}</option>
        </select>
    </form>
</div>