import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './login/login.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

/** Angular Fire **/
import { AngularFireModule } from '@angular/fire';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFirestoreModule } from '@angular/fire/firestore';

/** Enviroment **/
import { environment } from '../environments/environment';

/** Service Provider **/
import { AuthServiceService } from '../app/auth-service.service';
import { NavigationBarComponent } from './staticElements/navigation-bar/navigation-bar.component';
import { SetupComponent } from './page/setup/setup.component';
import { SetupPlanComponent } from './setup-plan/setup-plan.component';
import { ExpensesComponent } from './page/expenses/expenses.component';
import { MoneyBoxComponent } from './page/money-box/money-box.component';
import { PortfolioComponent } from './page/portfolio/portfolio.component';
import { HttpClientModule } from '@angular/common/http';


@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    NavigationBarComponent,
    SetupComponent,
    SetupPlanComponent,
    ExpensesComponent,
    MoneyBoxComponent,
    PortfolioComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    NgbModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireAuthModule,
    AngularFirestoreModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule
  ],
  providers: [AuthServiceService],
  bootstrap: [AppComponent]
})
export class AppModule { }
