import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { AuthServiceService } from '../auth-service.service';
import { Router } from "@angular/router"

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  loginForm = new FormGroup({
    email: new FormControl(''),
    password: new FormControl(''),
  });

  constructor(private authService: AuthServiceService, private router: Router) { }

  ngOnInit(): void {
    if (localStorage.getItem('user'))
      this.router.navigate(['/']);
  }

  onSubmit(loginData) {
    this.authService.doEmailLogin(loginData.email, loginData.password).then(user => {
      window.location.reload();
    });

  }

  logout() {
    this.authService.logOut();
    console.log('logout');
    window.location.reload();
  }
}
