// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebase: {
    apiKey: "AIzaSyDkARwHI86N-oTcOJzeedqHosY7yUKB-fE",
    authDomain: "myfinance-c4f07.firebaseapp.com",
    databaseURL: "https://myfinance-c4f07.firebaseio.com",
    projectId: "myfinance-c4f07",
    storageBucket: "myfinance-c4f07.appspot.com",
    messagingSenderId: "1048065463908",
    appId: "1:1048065463908:web:450696a483610220503171",
    measurementId: "G-2EVLHMZ0JZ"
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
