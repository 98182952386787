import { Component, OnInit } from '@angular/core';
import { AuthServiceService } from '../../auth-service.service';
import { Router } from '@angular/router';


const MENU_ITEMS = [
  {'path':'/transaction', 'label':'Transaction'},
  {'path':'/moneyBox', 'label':'Money Box'},
  {'path':'/portFolio', 'label':'Portfolio'}
];


@Component({
  selector: 'app-navigation-bar',
  templateUrl: './navigation-bar.component.html',
  styleUrls: ['./navigation-bar.component.css']
})
export class NavigationBarComponent implements OnInit {

  menuItems = MENU_ITEMS;
  showMobileMenu = false;

  constructor( private authService : AuthServiceService, private router: Router ) { }

  currentPath;

  ngOnInit(): void {
    this.router.events.subscribe(() => {
      this.currentPath = this.router.url;
    });
  }
 
  logout(){
    this.authService.logOut().then(res => {
      window.location.reload();
    });
  }

  toggleMobileMenu() {
    this.showMobileMenu = !this.showMobileMenu;
  }

  mobile_goToPath(path){
    this.router.navigate([path.replace('/','')]);
    this.toggleMobileMenu();
  }

}
