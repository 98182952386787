import { Component, HostListener } from '@angular/core';
import { FirestoreService } from '../../firestore.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder } from '@angular/forms';

const MONTHS = {
  '0': 'January',
  '1': 'February',
  '2': 'March',
  '3': 'April',
  '4': 'May',
  '5': 'June',
  '6': 'July',
  '7': 'August',
  '8': 'September',
  '9': 'October',
  '10': 'November',
  '11': 'December'
}

const SHORT_MONTHS = {
  '0': 'jan',
  '1': 'feb',
  '2': 'mar',
  '3': 'apr',
  '4': 'may',
  '5': 'jun',
  '6': 'jul',
  '7': 'aug',
  '8': 'sep',
  '9': 'oct',
  '10': 'nov',
  '11': 'dec'
}

@Component({
  selector: 'app-expenses',
  templateUrl: './expenses.component.html',
  styleUrls: ['./expenses.component.css']
})
export class ExpensesComponent {

  showExcelImport = false;
  currentMonth;
  currentMonthNumber;
  currentMonthCategories = [];
  currentYearPlan;
  currentYear = new Date().getFullYear();
  totalsForCategoryPlan;
  categoryTotals = [];
  isFirstLoad = true;
  isLoading;
  isMobileView = false;
  editMobileIsOpen = false;
  editMobileSelectedId; 

  totalIncome:string = '0.00';
  totalOutcome:string = '0.00';
  totalBalance:string = '0.00';
  totalExtraIncome:string = '0.00';
  totalExtraOutcome:string = '0.00';

  xlsColumns: string[] = [];
  xlsData: any[] = [];
  xlsMapping = new Object();

  expenses = [];

  constructor(private firestore: FirestoreService, private modalService: NgbModal) {
    this.currentMonthNumber = new Date().getMonth();
    this.currentMonth = MONTHS[this.currentMonthNumber];
    this.loadCategories();
    this.onResize(undefined);
  }

  showMapExcelColumns(event: ClipboardEvent, content) {
    this.xlsData = [];
    let clipboardData = event.clipboardData;
    let pastedText = clipboardData.getData('text');
    this.toggleExcelImport();
    let row_data = pastedText.split('\n');
    this.xlsColumns = row_data[0].split('\t');
    this.openXLSModal(content);
    delete row_data[0];
    // Create table dataSource
    let data = [];

    row_data.forEach(row_data => {
      let row = {};
      this.xlsColumns.forEach((a, index) => { row[a] = row_data.split('\t')[index] });
      data.push(row);
    })
    this.xlsData = data;
    this.xlsColumns.unshift('No Map');
  }

  importXlsData() {
    this.xlsData.forEach(xlsRow => {
      this.expenses.push(
        this.newExpense(
          xlsRow[this.xlsMapping['date']],
          xlsRow[this.xlsMapping['name']],
          xlsRow[this.xlsMapping['description']],
          parseFloat(xlsRow[this.xlsMapping['amount']].replace('.', '').replace(',', '.')),
          'No Category'
        )
      );
    });

    this.recalcCategoriesOverview();
    this.modalService.dismissAll();
  }

  addToExcelMapping(columns, event) {
    this.xlsMapping[columns] = event.target.options[event.target.selectedIndex].textContent;
  }

  async openXLSModal(content) {
    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' });
  }

  toggleExcelImport() {
    this.showExcelImport = !this.showExcelImport;
  }

  updateList(id: number, property: string, event: any) {
    let editField;

    if (property != 'category') {
      editField = this.checkInput(property, event.target.textContent);
      event.target.textContent = editField;
    } else {
      editField = event.target.options[event.target.selectedIndex].textContent;
    }

    this.expenses[id][property] = editField;
    this.recalcCategoriesOverview();
  }

  nextMonth() {
    this.currentYear = this.currentMonthNumber == 11 ? this.currentYear + 1 : this.currentYear;
    this.currentMonthNumber = (this.currentMonthNumber + 1) % 12;
    this.currentMonth = MONTHS[this.currentMonthNumber];
    this.loadCategories();
  }

  previousMonth() {
    this.currentMonthNumber = (this.currentMonthNumber - 1) % 12;
    if (this.currentMonthNumber < 0){
      this.currentMonthNumber = 11;
      this.currentYear--;
    }
      
    this.currentMonth = MONTHS[this.currentMonthNumber];
    this.loadCategories();
  }

  async loadCategories() {
    this.isLoading = true;
    if (this.isFirstLoad) {
      await this.sleep(500);
      this.isFirstLoad = false;
    }

      await this.firestore.getPlan(this.currentYear).then(p => {
        this.currentYearPlan = p;
      });
    

    this.totalsForCategoryPlan = await this.firestore.getTotalsForCategoryPlan(this.currentYearPlan, SHORT_MONTHS[this.currentMonthNumber]);
    this.totalsForCategoryPlan['No Category'] = 0;

    this.currentMonthCategories = this.firestore.getCategoriesFromPlan(this.currentYearPlan);
    this.currentMonthCategories.push('No Category');


    this.firestore.getExpenses(this.currentYear, this.currentMonth).then(res => {
      this.expenses = res;
      this.recalcCategoriesOverview();
      this.isLoading = false;
    });
  }

  sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  selectAllText(event) {
    window.getSelection()
      .selectAllChildren(
        event.target
      );
  }

  addExpense() {
    this.expenses.push(this.newExpense('Date', 'Title', 'Description', 0, 'No Category'));
    this.recalcCategoriesOverview()
  }

  newExpense(date: string, name: string, description: string, amount: number, category: string) {
    return {
      'date': date,
      'name': name,
      'description': description,
      'amount': amount,
      'category': category
    };
  }

  remove(id: any) {
    this.expenses.splice(id, 1);
    this.recalcCategoriesOverview();
  }

  recalcCategoriesOverview() {
    let categoryMap = new Map();
    this.totalIncome = '0';
    this.totalOutcome = '0';
    this.totalBalance = '0';
    this.totalExtraIncome = '0';
    this.totalExtraOutcome = '0';

    this.expenses.forEach(exp => {
      let categoryObject = categoryMap.has(exp.category) ? categoryMap.get(exp.category) : {
        'total': 0,
        'residue': parseFloat(this.totalsForCategoryPlan[exp.category]),
        'name': exp.category
      };

      categoryObject.total = (parseFloat(categoryObject.total) + parseFloat(exp.amount)).toFixed(2);
      categoryObject.residue = (parseFloat(categoryObject.residue) + parseFloat(exp.amount)).toFixed(2);


      categoryMap.set(exp.category, categoryObject);
      this.recalTotals(exp);
    });

    this.categoryTotals = Array.from(categoryMap.values());
  }

  checkInput(category, input) {
    let tempVal = input;

    if (category == 'amount') {
      tempVal = parseFloat(input);
      tempVal = isNaN(tempVal) ? 0 : tempVal;
    }

    return tempVal;
  }

  uploadExpences() {
    this.firestore.saveExpences(this.currentYear, this.currentMonth, {
      'expenses': this.expenses,
      'month': this.currentMonth,
      'year': this.currentYear
    });
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.isMobileView = window.innerWidth > 599 ? false : true;
  }

  openEditTransactionMobile(id) {
    this.editMobileIsOpen = true;
    this.editMobileSelectedId = id;
  }

  closeEditTransactionMobile() {
    this.editMobileIsOpen = false;
    this.recalcCategoriesOverview();
  }

  recalTotals(exp){

    let amount = exp.amount;

    if(amount > 0 && exp.category != 'No Category'){
      this.totalIncome = (parseFloat(this.totalIncome) + parseFloat(amount)).toFixed(2);
    }

    if(amount < 0 && exp.category != 'No Category'){
      this.totalOutcome = (parseFloat(this.totalOutcome) + parseFloat(amount)).toFixed(2);
    }

    if(amount > 0 && exp.category == 'No Category') {
      this.totalExtraIncome = (parseFloat(this.totalExtraIncome) + parseFloat(amount)).toFixed(2);
    }

    if(amount < 0 && exp.category == 'No Category') {
      this.totalExtraOutcome = (parseFloat(this.totalExtraOutcome) + parseFloat(amount)).toFixed(2);
    }

    this.totalBalance = (parseFloat(this.totalIncome) + parseFloat(this.totalOutcome)).toFixed(2);
  }

}
