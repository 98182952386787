<div class="setupContainer">
    <div class="verticalMenu">
        <h4>Setup</h4>

        <ul class="list-group">
            <li [routerLink]="[{ outlets: { 'setup': ['plans'] } }]" class="list-group-item">Plans</li>
            <!--li class="list-group-item">Year</li>
            <li class="list-group-item">Other</li-->
        </ul>
    </div>
    <div class="section">
        <router-outlet name="setup"></router-outlet>
    </div>
</div>