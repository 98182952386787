import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore } from '@angular/fire/firestore';

@Injectable({
  providedIn: 'root'
})
export class AuthServiceService {

  user;

  constructor( public afAuth: AngularFireAuth, private firestore: AngularFirestore ) { 
    this.afAuth.authState.subscribe(user => {
      if (user){
        this.user = user;
        localStorage.setItem('user', JSON.stringify(this.user));
      } else {
        localStorage.removeItem('user');
      }
    })
  }

  async doEmailLogin(email: string, password: string){
    return await this.afAuth.signInWithEmailAndPassword(email, password);
    
  }

  async logOut(){
    await this.afAuth.signOut();
    localStorage.removeItem('user');
  }

  isLoggedIn(){
    const  user  =  JSON.parse(localStorage.getItem('user'));
    return  user  !==  null;
  }
}
