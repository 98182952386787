import { Component } from '@angular/core';
import { FirestoreService } from '../../firestore.service';

const SHORT_MONTHS = {
  'January': 'jan',
  'February': 'feb',
  'March': 'mar',
  'April': 'apr',
  'May': 'may',
  'June': 'jun',
  'July': 'jul',
  'August': 'aug',
  'September': 'sep',
  'October': 'oct',
  'November': 'nov',
  'December': 'dec',
}

@Component({
  selector: 'app-money-box',
  templateUrl: './money-box.component.html',
  styleUrls: ['./money-box.component.css']
})
export class MoneyBoxComponent {

  isLoading;
  moneyBoxList = [];
  currentYear = new Date().getFullYear();
  totalAmount = '0';
  cash = '0';

  constructor(private firestore: FirestoreService) {
    this.retriveExpenses();
  }

  async retriveExpenses() {
    this.moneyBoxList = [];
    this.totalAmount = '0';
    this.isLoading = true;
    let selectedMonths;
    let annualCategoriesExpenses;
    let plan;

    await this.sleep(500);
    await this.firestore.getYearExpencesByCategory(this.currentYear).then(result => {
      selectedMonths = result.selectedMonths;
      annualCategoriesExpenses = result.expenseCategoryMap;
    });

    await this.firestore.getPlan(this.currentYear).then(retrievedPlan => {
      plan = retrievedPlan;
    });

    this.isLoading = false;
    this.calcMoneyBox(plan, annualCategoriesExpenses, selectedMonths);
  }

  calcMoneyBox(plans, annualCategoriesExpenses, selectedMonths) {
    plans.categories.forEach(category => {
      let calculatedAmount = annualCategoriesExpenses.has(category.name) ? annualCategoriesExpenses.get(category.name).amount : 0;
      let moneyBox =
      {
        'name': category.name,
        'amount': parseFloat(calculatedAmount).toFixed(2),
        'residue': calculatedAmount
      }

      console.log('SelectedMonths: ', selectedMonths);

      selectedMonths.forEach(month => {
        moneyBox.residue = (parseFloat(moneyBox.residue) + parseFloat(category[SHORT_MONTHS[month]])).toFixed(2);
      });

      moneyBox.residue = category.startValue ? (parseFloat(category.startValue) + parseFloat(moneyBox.residue)).toFixed(2) : moneyBox.residue;

      this.totalAmount = (parseFloat(this.totalAmount) + parseFloat(moneyBox.residue)).toFixed(2);
      this.cash = (parseFloat(this.cash) + parseFloat(calculatedAmount)).toFixed(2);
      this.moneyBoxList.push(moneyBox);
    });
  }

  sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  nextYear() {
    this.currentYear++;
    this.retriveExpenses();
  }

  previousYear() {
    this.currentYear--;
    this.retriveExpenses();
  }

}
