import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { ExpensesComponent } from './page/expenses/expenses.component';

@Injectable({
  providedIn: 'root'
})
export class FirestoreService {

  constructor(private afs: AngularFirestore) { }

  async getPlan(year: number) {
    let plan;
    let currentUID = JSON.parse(localStorage.getItem('user')).uid;
    await this.afs.collection('userData').doc(currentUID)
      .collection('plans').doc(year + '')
      .get().toPromise().then(retPlan => {
        plan = retPlan.data();
      });
    return plan;
  }

  async addPlan(plan) {
    let currentUID = JSON.parse(localStorage.getItem('user')).uid;
    await this.afs.collection('userData').doc(currentUID).collection('plans').doc(plan.year + '').set(plan);
  }

  getCategoriesFromPlan(plan) {
    let categories = [];

    if (!plan)
      return categories;

    plan.categories.forEach(category => {
      categories.push(category.name);
    });

    return categories;
  }

  getTotalsForCategoryPlan(plan, month) {
    let categoriesTotal = new Object();
    plan.categories.forEach(element => {
      categoriesTotal[element.name] = element[month];
    });

    return categoriesTotal;
  }

  async saveExpences(year, month, expences) {
    let currentUID = JSON.parse(localStorage.getItem('user')).uid;

    if (expences.expenses.length == 0) {
      await this.afs.collection('userData').doc(currentUID).collection('expences').doc(`${month}_${year}`).delete();
      return;
    }

    await this.afs.collection('userData').doc(currentUID).collection('expences').doc(`${month}_${year}`).set(expences);
  }

  async getExpenses(year, month) {
    let currentUID = JSON.parse(localStorage.getItem('user')).uid;
    let expenses;
    await this.afs.collection('userData').doc(currentUID)
      .collection('expences').doc(`${month}_${year}`)
      .get().toPromise().then(exp => {
        expenses = exp.data() ? exp.data().expenses : [];
      });

    return expenses;
  }

  async getYearExpencesByCategory(year) {
    let currentUID = JSON.parse(localStorage.getItem('user')).uid;
    let retrievedExpencesMap = new Map();
    let retrievedMonths = [];
    await this.afs.collection('userData').doc(currentUID)
      .collection('expences', ref => ref.where('year', '==', year)).get().toPromise().then(expensesList => {
        expensesList.forEach(expensesItem => {
          retrievedMonths.push(expensesItem.data().month);
          expensesItem.data().expenses.forEach(expense => {
            let preexinentExpense = retrievedExpencesMap.has(expense.category) ?
              retrievedExpencesMap.get(expense.category) : { 'category': expense.category, 'amount': 0 };
            preexinentExpense.amount = preexinentExpense.amount + expense.amount;
            retrievedExpencesMap.set(expense.category, preexinentExpense);
          });
        });
      });

    return { 'expenseCategoryMap': retrievedExpencesMap, selectedMonths: retrievedMonths };
  }

  async savePortfolio(portfolio) {
    let currentUID = JSON.parse(localStorage.getItem('user')).uid;
    await this.afs.collection('userData').doc(currentUID).collection('portfolio').doc('portfolio').set(portfolio);
  }

  async getPortfolio() {
    let currentUID = JSON.parse(localStorage.getItem('user')).uid;
    let portfolio;
    await this.afs.collection('userData').doc(currentUID)
      .collection('portfolio').doc('portfolio')
      .get().toPromise().then(retPortfolio => {
        portfolio = retPortfolio.data();
    });

    return portfolio;
  }
}
