import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class YahooFinanceService {

  constructor( private http: HttpClient ) { }

  async searchSymbol(keyWord) {
    //let endpoint = 'https://query2.finance.yahoo.com/v1/finance/search';
    let endpoint = 'https://fjo9mtd02f.execute-api.eu-west-1.amazonaws.com/default/searchSymbol';
    let params = new HttpParams();
    let searchResults;

    //Adding callout params
    params = params.append('q',keyWord);
    params = params.append('lang', 'en-US');
    params = params.append('lang', 'en-US');
    params = params.append('region', 'US');
    params = params.append('quotesCount', '5');
    params = params.append('newsCount', '0');
    params = params.append('enableFuzzyQuery', 'false');

    await this.http.get(endpoint, {params : params}).toPromise().then(res => {
      searchResults = res;
    });

    return searchResults;
  }

  async getSymbolInfo(symbol) {
    //let endpoint = 'https://query1.finance.yahoo.com/v8/finance/chart/';
    let endpoint = 'https://wk4hchufff.execute-api.eu-west-1.amazonaws.com/default/getChart';
    let params = new HttpParams();
    let symbolInfo;

    params = params.append('region','US');
    params = params.append('lang','en-US');
    params = params.append('includePrePost','false');
    params = params.append('interval','1d');
    params = params.append('range','2d');
    params = params.append('corsDomain','finance.yahoo.com');
    params = params.append('.tsrc','finance');
    params = params.append('symbolName', symbol);

    await this.http.get(endpoint, {params : params}).toPromise().then(res => {
      symbolInfo = res;
    });

    return symbolInfo;

  }
}
