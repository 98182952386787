import { Component, OnInit } from '@angular/core';
import { NgControlStatus } from '@angular/forms';
import { FirestoreService } from 'src/app/firestore.service';
import { YahooFinanceService } from 'src/app/yahoo-finance.service';

@Component({
  selector: 'app-portfolio',
  templateUrl: './portfolio.component.html',
  providers: [
    YahooFinanceService,
    FirestoreService
  ],
  styleUrls: ['./portfolio.component.css']
})
export class PortfolioComponent implements OnInit {

  mySymbols = [];
  symbols = [];
  symbolsPrice = [];
  openSectionArray = [];
  showAddContainer = false;
  timer;
  portfolioValue = 0;
  portfolioActualValue = 0;
  portfolioActualValuePerc = 0;
  dailyGain = 0;
  totalchartPreviousClose = 0;

  constructor(private yahooService: YahooFinanceService, private firestore: FirestoreService) { }

  async ngOnInit() {
    await this.sleep(500);
    await this.retrievePortfolio();
  }

  sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  toggleShowAddContainer() {
    this.symbols = [];
    this.showAddContainer = !this.showAddContainer;
  }

  addToSymbols(id) {
    this.mySymbols = this.mySymbols.concat(this.symbols[id]);
    this.mySymbols[this.mySymbols.length -1]['lots'] = [];
    this.toggleShowAddContainer();
    this.retrieveSymbolsPrice();
  }

  removeSymbol(id) {
    this.mySymbols.splice(id, 1);
  }

  async searchSymbol(event) {
    if (event.target.value.length > 2) {
      if (this.timer) {
        clearTimeout(this.timer);
      }

      this.timer = setTimeout(() => {
        this.yahooService.searchSymbol(event.target.value).then(res => {
          this.symbols = res.quotes;
        });
      }, 500);
    }
  }

  toggleOpenSection(id) {
    if(!this.openSectionArray[id]) {
      this.openSectionArray[id] = true;
    }else {
      this.openSectionArray[id] = false;
    } 
  }

  async retrieveSymbolsPrice() {
    this.portfolioActualValue = 0;
    this.portfolioActualValuePerc = 0;
    this.portfolioValue = 0;
    this.dailyGain = 0;
    this.totalchartPreviousClose = 0;
    
    await Promise.all(this.mySymbols.map( async (symbol, i) => {
      await this.yahooService.getSymbolInfo(symbol.symbol).then(info => {
        this.symbolsPrice[i] = info.chart.result[0];
        this.recalcSymbolTotals(i);
      });
    }));
  }

  recalcSymbolTotals(id) {
    let lotTotalAmount = 0;
    let lotTotalShares = 0;
    let dailyDifferences = this.symbolsPrice[id].meta.regularMarketPrice - this.symbolsPrice[id].meta.chartPreviousClose;
    let dailyDifferencesPerc = ( dailyDifferences / this.symbolsPrice[id].meta.chartPreviousClose ) * 100;

    this.mySymbols[id].lots.forEach(lot => {
      lot.totalAmount = (lot.shares * lot.cost) + parseFloat(lot.commission);
      lot.totalGain = (lot.shares * this.symbolsPrice[id].meta.regularMarketPrice) - lot.totalAmount;
      lot.totalGainPerc = (lot.totalGain / lot.totalAmount ) * 100;

      lot.dayGain = dailyDifferences * lot.shares;
      lot.dayGainPerc = dailyDifferencesPerc;

      console.log()

      lotTotalShares += lot.shares;
      lotTotalAmount = lotTotalAmount + parseFloat(lot.totalAmount);
    });

    this.symbolsPrice[id].meta.totalGain = (lotTotalShares * this.symbolsPrice[id].meta.regularMarketPrice) - lotTotalAmount;
    this.symbolsPrice[id].meta.totalGainPerc = ( this.symbolsPrice[id].meta.totalGain / lotTotalAmount ) * 100;

    this.symbolsPrice[id].meta.totalDailyGain = dailyDifferences * lotTotalShares;
    this.symbolsPrice[id].meta.totalDailyGainPerc = dailyDifferencesPerc;

    this.portfolioValue = this.portfolioValue + lotTotalAmount;
    this.portfolioActualValue = this.portfolioActualValue + lotTotalShares * this.symbolsPrice[id].meta.regularMarketPrice;
    this.totalchartPreviousClose = this.totalchartPreviousClose + (this.symbolsPrice[id].meta.chartPreviousClose * lotTotalShares);
    this.dailyGain = this.dailyGain + this.symbolsPrice[id].meta.totalDailyGain;
  }

  addLot(id) {
    this.mySymbols[id].lots.push(this.generateEmptyLot());
  }

  deleteLot(id, lotId) {
    this.mySymbols[id].lots.splice(lotId, 1);
  }

  generateEmptyLot() {
    return {
      'tradeDate' : '',
      'shares' : '0',
      'cost' : '0',
      'dayGain' : '0',
      'totalGain' : '0',
      'commission' : '0'
    }
  }

  savePortfolio() {
    this.firestore.savePortfolio({ 'mySymbols' : this.mySymbols});
    this.retrieveSymbolsPrice();
  }

  async retrievePortfolio() {
    await this.firestore.getPortfolio().then(retPortfolio => {
      if(retPortfolio) {
        this.mySymbols = retPortfolio.mySymbols;
        this.retrieveSymbolsPrice();
      }else {
        this.mySymbols = [];
      }
    });
  }

}
