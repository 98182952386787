import { Component, OnInit, ElementRef } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { FirestoreService } from '../firestore.service';

@Component({
  selector: 'app-setup-plan',
  templateUrl: './setup-plan.component.html',
  styleUrls: ['./setup-plan.component.css']
})
export class SetupPlanComponent implements OnInit {

  years = [];
  selectedYear;
  categoriesMap = new Map();
  totals;
  currentYear;
  categories = [];

  newCategory = new FormGroup({
    name: new FormControl(''),
    amount: new FormControl(''),
  });

  constructor(private firestore: FirestoreService) {
    this.currentYear = new Date().getFullYear();
    this.loadPlan();
  }

  ngOnInit(): void {
    this.recalcTotals();
  }

  clearSelectedYear() {
    this.selectedYear = undefined;
  }

  addCategory(categoryData) {
    if (categoryData.name != '' && categoryData.amount != '') {
      this.categories.push(this.inizializeCategory(categoryData.name, categoryData.amount));
    }
    this.recalcTotals();
  }

  updateList(id: number, property: string, event: any) {
    const editField = event.target.textContent;
    event.target.textContent = editField;
    this.categories[id][property] = editField;
    this.recalcTotals();
  }

  remove(id: any) {
    this.categories.splice(id, 1);
    this.recalcTotals();
  }

  inizializeCategory(name: string, amount: number) {
    let category = new Object();
    category['name'] = name;
    category['jan'] = amount;
    category['feb'] = amount;
    category['mar'] = amount;
    category['apr'] = amount;
    category['may'] = amount;
    category['jun'] = amount;
    category['jul'] = amount;
    category['aug'] = amount;
    category['sep'] = amount;
    category['oct'] = amount;
    category['nov'] = amount;
    category['dec'] = amount;
    category['startValue'] = 0;
    return category;
  }

  recalcTotals() {
    let totals = this.inizializeCategory('TOTALS', 0);
    this.categories.forEach(category => {
      totals['jan'] = (parseFloat(totals['jan']) +  parseFloat(category['jan'])).toFixed(2);
      totals['feb'] = (parseFloat(totals['feb']) +  parseFloat(category['feb'])).toFixed(2);
      totals['mar'] = (parseFloat(totals['mar']) +  parseFloat(category['mar'])).toFixed(2);
      totals['apr'] = (parseFloat(totals['apr']) +  parseFloat(category['apr'])).toFixed(2);
      totals['may'] = (parseFloat(totals['may']) +  parseFloat(category['may'])).toFixed(2);
      totals['jun'] = (parseFloat(totals['jun']) +  parseFloat(category['jun'])).toFixed(2);
      totals['jul'] = (parseFloat(totals['jul']) +  parseFloat(category['jul'])).toFixed(2);
      totals['aug'] = (parseFloat(totals['aug']) +  parseFloat(category['aug'])).toFixed(2);
      totals['sep'] = (parseFloat(totals['sep']) +  parseFloat(category['sep'])).toFixed(2);
      totals['oct'] = (parseFloat(totals['oct']) +  parseFloat(category['oct'])).toFixed(2);
      totals['nov'] = (parseFloat(totals['nov']) +  parseFloat(category['nov'])).toFixed(2);
      totals['dec'] = (parseFloat(totals['dec']) +  parseFloat(category['dec'])).toFixed(2);
    });
    this.totals = totals;
  }

  nextYear() {
    this.currentYear++;
    this.loadPlan();
  }

  priorYear() {
    this.currentYear--;
    this.loadPlan();
  }

  savePlan() {
    let plan = {
      'year': this.currentYear,
      'categories': this.categories
    };
    this.firestore.addPlan(plan);
  }

  loadPlan() {
    this.firestore.getPlan(this.currentYear).then(plan => {
      if (plan) {
        this.categories = plan.categories;
        this.recalcTotals();
      } else {
        this.categories = [];
        this.totals = undefined;
      }
    });
  }

}
